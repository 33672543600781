<template>
  <div>
    <div>
      <!-- Filters -->
      <b-card-actions title="Filters" action-collapse>
        <div>
          <b-row>
            <b-col md="2" lg="2" xs="12">
              <b-form-group label-for="searchTerm">
                <b-form-input
                    v-on:keyup="onSearch"
                    v-on:input="taskFiltering"
                    v-model.lazy="searchTerm"
                    placeholder="Search by Task Name ..."
                    type="text"
                    class="mr-sm-1 mb-1 mb-sm-0 custom-font"
                />
              </b-form-group>
            </b-col>

            <b-col md="2" lg="2" xs="12">
              <v-select
                  v-model="filterProjectId"
                  :options="filterProjectIdOption"
                  v-on:input="filterProject"
                  :reduce="(task) => task.id"
                  label="name"
                  placeholder="Select Project"
                  class="mr-sm-1 mb-1 mb-sm-0 custom-font"
              />
            </b-col>

            <b-col md="2" lg="2" xs="12">
              <v-select
                  v-model="filterMilestoneBoardId"
                  :options="filterMilestoneBoardIdOption"
                  v-on:input="filterMilestoneBoard"
                  :reduce="(board) => board.id"
                  label="name"
                  placeholder="Select Milestone Board"
                  class="mr-sm-1 mb-1 mb-sm-0 custom-font"
              />
            </b-col>

            <b-col md="2" lg="2" xs="12">
              <v-select
                  v-model="filterSupervisorId"
                  :options="filterAllUserIdOption"
                  v-on:input="filterSupervisor"
                  :reduce="(member) => member.id"
                  label="name"
                  placeholder="Select Supervisor"
                  class="mr-sm-1 mb-1 mb-sm-0 custom-font"
              >
                <template #option="data">
                  <UserSelect :user="data" />
                </template>
              </v-select>
            </b-col>

            <b-col md="2" lg="2" xs="12">
              <v-select
                  v-model="filterTaskStatus"
                  :options="filterTaskStatusOption"
                  v-on:input="filterTaskStatusData"
                  :reduce="(item) => item.value"
                  label="name"
                  placeholder="Select Status"
                  class="mr-sm-1 mb-1 mb-sm-0 custom-font"
              />
            </b-col>

            <b-col md="2" lg="2" xs="12">
              <flat-pickr
                  v-model="filterRangeDate"
                  v-on:input="filterDeadline"
                  class="form-control"
                  placeholder="Select Deadline"
                  :config="{ mode: 'range'}"
              />
            </b-col>

          </b-row>
        </div>
      </b-card-actions>

    </div>

    <b-card>
      <div>
        <!-- table -->
        <vue-good-table
          mode="remote"
          styleClass="vgt-table table-custom-style striped"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          :totalRows="totalRecords"
          :isLoading.sync="isLoading"
          :rows="rows"
          :sort-options="{
            enabled: true,
            multipleColumns: true,
            initialSortBy: [{ field: 'created_at', type: 'desc' }],
          }"
          :columns="columns"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <!-- title -->
            <template v-if="props?.column?.field === 'format_tasks'">
              <span>
                <b-badge
                    v-if="props?.row?.is_completed"
                    variant="light-success"
                    class="badge-glow"
                >
                  Completed
                </b-badge>
                <b-badge
                    v-if="props?.row?.overdue"
                    variant="light-danger"
                >
                Overdue
              </b-badge>
                <b-link
                  v-on:click="
                    onShowTaskDetailsPage(props?.row?.milestoneTaskBoard?.data?.id, props.row.id, props.row.short_title)"
                >
                <div class="font-weight-bold" style="color:black" v-html="breakTitle(props?.row?.title)"></div>

                </b-link>
              </span>

              <div>
                Phase:
                <template v-if="props?.row?.phase?.data?.title">
                  <b-badge variant="light-primary">
                    {{ props?.row?.phase?.data?.title }}
                  </b-badge>
                </template>

                <b-badge v-else variant="light-danger" pill>
                  N / A
                </b-badge>
              </div>

              <div>
                <template v-if="props?.row?.word_count">
                  Word Count:
                  <b-badge variant="light-info">
                    {{ props?.row?.word_count }}
                  </b-badge>
                </template>
              </div>
            </template>

            <template v-if="props?.column?.field === 'format_member_info'">
              <span v-for="(member, index) in props.row.team.data" :key="index">
                <template v-if="index <= 2">
                  <b-avatar :src="member?.avatar === '' ? '/avatar.svg' : member?.avatar" size="26" style="margin: 1px"
                />
                </template>
              </span>

              <span v-if="props?.row?.team_count > 0">
                <span v-if="props?.row?.team_count > 3">
                <b-link
                    v-on:click="
                    showAllAddedMemberModal(props?.row?.team?.data)
                  "
                >
                  + {{ props?.row?.team_count - 3 }} more
                </b-link>
              </span>
              </span>
              <b-badge v-else variant="danger">
                N / A
              </b-badge>

            </template>

            <template v-if="props?.column?.field === 'format_phase_info'">
              <b-badge variant="primary">
                {{ props?.row?.phase?.data?.title }}
              </b-badge>
            </template>

            <template v-if="props?.column?.field === 'format_supervisor'">

              <UserCard :user="props?.row?.milestoneTaskBoard?.data?.supervisor?.data" />

            </template>


            <template v-if="props?.column?.field === 'format_date'">
              <b>Deadline: </b>
              <span v-if="props?.row?.deadline">
                {{ formatDate(props?.row?.deadline) }}
              </span>
              <span v-else>
                <b-badge pill variant="light-primary"> N/A </b-badge>
              </span>
              <br />

              <b>Actual Start Date: </b>
              <span v-if="props?.row?.actual_start_date">
                {{ formatDate(props?.row?.actual_start_date) }}
              </span>
              <span v-else>
                <b-badge pill variant="light-primary"> N/A </b-badge>
              </span>
              <br />
              <b>Actual End Date: </b>
              <span v-if="props?.row?.actual_end_date">
                {{ formatDate(props?.row?.actual_end_date) }}
              </span>
              <span v-else>
                <b-badge pill variant="light-primary"> N/A </b-badge>
              </span>
            </template>

            <!-- Column:  Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['10', '20', '50']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>

        <!-- table -->
      </div>

      <b-modal
        id="modal-all-added-member-form"
        centered
        title="Assigned Members"
        hide-footer
        @hidden="hiddenAllAddedMemberModal"
        no-close-on-backdrop
      >
        <ul>
          <li v-for="(member, index) in assingedMember" :key="index">
            <b-avatar :src="member?.avatar === '' ? '/avatar.svg' : member?.avatar" size="26" style="margin: 1px" />
            {{ member.name }}
          </li>
        </ul>
      </b-modal>
    </b-card>
  </div>
</template>

  <script>
  import {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BForm,
    BModal,
    BSpinner,
    BFormTextarea,
    BFormDatepicker,
    BLink,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    VBTooltip, BFormCheckbox,
  } from 'bootstrap-vue'
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  required as validate_required,
  max,
  size,
  ext,
  numeric as validate_numeric,
} from "@validations";
import flatPickr from "vue-flatpickr-component";
import FinancialEstimationForm from "@/views/admin/finance/project/FinancialEstimationForm.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import { projectTypeConstants } from "@/helpers/constant/projectTypeConstant";
import { paymentTypeConstants } from "@/helpers/constant/paymentTypeConstant";
import {
  LEAD_MANAGEMENT_ACCESS,
  LEAD_DETAILS_EXPORT,
  LEAD_DETAILS_CREATE,
  LEAD_DETAILS_EDIT,
  LEAD_DETAILS_DELETE,
} from "@/helpers/permissionsConstant";
import { milestoneTaskBoardStatusConstants } from "@/helpers/constant/milestoneTaskBoardStatusConstant.js";
import { taskDateTypeConstant } from "@/helpers/constant/taskDateTypeConstant.js";
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

import { filterMilestoneOrTaskStatusTypeOptionConstant } from "@/helpers/constant/filterMilestoneOrTaskStatusTypeOptionConstant.js";
  import UserSelect from '@/layouts/components/UserSelect.vue'
  import UserCard from '@/layouts/components/UserCard.vue'
  import {formatDate, formatDateRange} from "@/helpers/helpers";
  import store from "@/store";
export default {
  name: "MilestoneBoardGlobalList",
  components: {
    UserCard,
    UserSelect,
    BFormCheckbox,
    BLink,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BRow,
    BCol,
    BFormTextarea,
    BFormDatepicker,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    flatPickr,
    BMedia,
    BMediaAside,
    BMediaBody,
    FinancialEstimationForm,
    BCardActions,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      LEAD_MANAGEMENT_ACCESS,
      LEAD_DETAILS_EXPORT,
      LEAD_DETAILS_CREATE,
      LEAD_DETAILS_EDIT,
      LEAD_DETAILS_DELETE,
      //Custom Validation
      amountNotMatch: false,
      amountNotMatchError: "",
      vatNotMatch: false,
      vatNotMatchError: "",
      taxNotMatch: false,
      taxNotMatchError: "",
      estimationSubmitDisabled: false,
      denounceTimeout: null,
      // Financial Estimation
      showFinancialEstimationComponent: false,
      selectedLeadInfo: {},
      projectTypeConstants,
      paymentTypeConstants,
      filterRangeDate: "",
      projectType: "",
      paymentType: "",
      amount: "",
      timeDuration: "",
      vatAmount: "",
      taxAmount: "",
      grandAmount: "",
      milestones: "",
      milestoneAmmount: [],
      milestoneVat: [],
      milestoneTax: [],
      milestoneTotal: [],

      milestoneDeadLine: [],
      milestonePaymentType: [],
      isMileStoneLoading: false,
      isFinancialEstimationFormLoading: false,

      switchValue: false,

      //Table
      milestoneTaskBoardStatusConstants,
      taskDateTypeConstant,
      leadId: "",
      modelType: "",
      lead_category_id: "",
      categoryIdOption: [],
      lead_source_id: "",
      sourceIdOption: [],
      department_id: "",
      dial_code_country_id: "",
      dialCodeCountryIdOption: [],
      lead_status_id: "",
      statusIdOption: [],
      user_id: "",
      userIdOption: [],
      allUserIdOption: [],
      title: "",
      keyContactPerson: "",
      company_name: "",
      country_id: "",
      countryIdOption: [],
      email: "",
      mobile: "",
      phone: "",
      address: "",
      city: "",
      state: "",
      street: "",
      zip_code: "",
      note: "",
      website_link: "",
      leadStatusInfo: [],

      // Assigned Member
      assingedMember: [],

      //filter options
      filterSourceId: "",
      filterSourceIdOption: [],
      filterStatusId: "",
      filterStatusIdOption: [],
      filterCategoryId: "",
      filterCategoryIdOption: [],
      filterEmpId: "",
      filterEmpIdOption: [],
      filterMilestoneBoardId: "",
      filterPhaseId: "",
      filterTaskId: "",
      filterMilestoneOrTaskStatusType: "task_status",
      filterMilestoneStatus: "",
      filterTaskStatus: "",
      filterDateType: "assigned",
      filterSubTaskId: "",
      filterDepertmentId: "",
      filterAuthUserId: "",
      filterSupervisorId: "",
      filterProjectId: "",
      filterMilestoneBoardIdOption: [],
      filterProjectIdOption: [],
      filterDepertmentIdOption: [],
      filterAllUserIdOption: [],
      filterMemberIdOption: [],
      filterTaskIdOption: [],
      filterPhaseIdOption: [],
      filterSubTaskIdOption: [],
      filterCountryId: "",
      filterCountryIdOption: [],
      filterStartDate: "",
      filterEndDate: "",
      filterMilestoneOrTaskStatusTypeOptionConstant,
      filterMilestoneStatusOption: [],
      filterTaskStatusOption: [
        { value: false, name: "Not Completed" },
        { value: true, name: "Completed" },
        { value: "overdue", name: "Overdue" },
        { value: "not_started", name: "Not Started" },
        { value: "ongoing", name: "On Going" },
      ],
      params: [],
      pageLength: 10,
      columns: [
        {
          label: "Task",
          field: "format_tasks",
          sortable: false,
        },
        {
          label: "Member",
          field: "format_member_info",
          sortable: false,
        },
        {
          label: "Supervisor",
          field: "format_supervisor",
          sortable: false,
        },
        {
          label: "Date",
          field: "format_date",
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: "",
      sourceId: "",
      statusId: "",
      categoryId: "",
      empId: "",
      departmentId: "",
      delayTimer: null,
      isLoading: false,
      isLeadsFormSubmitLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          // { field: "id", type: "desc" },
          // { field: "name", type: "desc" },
          { field: "created_at", type: "desc" },
        ],
        page: 1,
        perPage: 10,
      },
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
      authUser: "userModule/getUser",
      filters: "allTaskModule/getFilters",
    }),
  },

  async created() {
    // auth filter auth users tasks
    this.filterAuthUserId = this.authUser.id;
    //this.filterSupervisorId = this.authUser.id;

    try {
      const [depertments, members, allMembers] = await Promise.all([
        this.getDepertments(),
        this.getDepartmentWiseAssignEmployee(),
        this.getAllUsers(),
      ]);

      // filter depertment
      this.filterDepertmentIdOption = (depertments?.data?.data || []).map(
        (item) => {
          let name = item.name;
          return {
            name,
            id: item.id,
          };
        }
      );

      //filter member
      this.filterAllUserIdOption = (allMembers?.data?.data || []).map(
        (item) => {
          return {
            avatar: item?.avatar,
            name: item?.name,
            email: item?.email,
            mobile: item?.mobile,
            employee_number: item?.employee_number,
            id: item?.id,
            departmentId: item?.department_id,
          }
        }
      );

      this.setMilestoneBoardInfo();

      //get project approved options
      this.getApprovedProjects();

      this.filterProjectId = this.filters?.filterProjectId
      this.filterMilestoneBoardId = this.filters?.filterMilestoneBoardId
      this.filterTaskStatus = this.filters?.filterTaskStatus
      this.filterSupervisorId = this.filters?.filterSupervisorId
      this.filterRangeDate = this.filters?.filterDeadline

      await this.loadItems();

    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    formatDate,

    filterProject(){
      this.$store.dispatch("allTaskModule/setFilter", {
        key: "filterProjectId",
        value: this.filterProjectId,
      });

      this.loadItems()
    },

    filterDeadline() {
      this.$store.dispatch("allTaskModule/setFilter", {
        key: "filterDeadline",
        value: this.filterRangeDate,
      });

      this.loadItems()
    },

    async getApprovedProjects() {
      const [allApprovedProjects] = await Promise.all([
        this.$api.get(`api/projects/approve`),
      ]);

      this.filterProjectIdOption = (
          allApprovedProjects?.data?.data || []
      ).map((item) => {
        let name = item.lead.title;
        return {
          name,
          id: item.id,
        };
      });
    },

    async setMilestoneBoardInfo(deptId, departmentMilestones) {

      try {

        const [allMilestoneBoard] = await Promise.all([
          this.$api.get(`api/milestone-task-boards/all/boards?userId=${this.authUser?.id}`),
        ]);

        this.filterMilestoneBoardIdOption = (
            allMilestoneBoard?.data?.data || []
        ).map((item) => {
          let name = item.title;
          return {
            name,
            id: item.id,
          };
        });

      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    // break title
    breakTitle(title) {
      const maxLength = 50;
      if (title && title.length > maxLength) {
        const regex = new RegExp(`(.{1,${maxLength}})`, 'g');
        const truncatedTitle = title.replace(regex, "$1<br>");
        return truncatedTitle;
      }
      return title;
    },

    filterMilestoneBoard(){
      this.$store.dispatch("allTaskModule/setFilter", {
        key: "filterMilestoneBoardId",
        value: this.filterMilestoneBoardId,
      });

      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },
    onShowTaskDetailsPage(board,id, task) {
      this.$router.push({
        name: 'admin-board-task-details',
        params: {
          board,
          id,
          task,
        },
      })
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },
    showAllAddedMemberModal(value) {
      this.assingedMember = value.map((item) => {
        let name = `${item?.name} (${item?.email})`;
        return {
          name,
          id: item.id,
          avatar: item?.avatar,
        };
      });

      this.$bvModal.show("modal-all-added-member-form");
    },
    hiddenAllAddedMemberModal() {
      this.$bvModal.hide("modal-all-added-member-form");
    },
    async getGlobalData(params) {
      return await this.$api.get(
        "api/milestone-task-boards/tasks/all?include=phase,milestoneTaskBoard.department,milestoneTaskBoard.supervisor,team",
        {
          params: {
            show: params.show,
            page: params.page,
            sort: params.sort,
            q: params.q,
            filterMilestoneBoardId: params.filterMilestoneBoardId,
            filterDepertmentId: params.filterDepertmentId,
            filterSupervisorId: params.filterSupervisorId,
            filterAuthUserId: params.filterAuthUserId,
            filterPhaseId: params.filterPhaseId,
            filterTaskId: params.filterTaskId,
            filterTaskStatus: params.filterTaskStatus,
            filterMilestoneStatus: params.filterMilestoneStatus,
            filterDateType: params.filterDateType,
            filterDeadlineFrom: params.filterStartDate,
            filterDeadlineTo: params.filterEndDate,
            filterProjectId: params.filterProjectId,
            filterProjectLessBoardId: params.filterProjectLessBoardId,
          },
        }
      );
    },

    async getDepertments() {
      return await this.$api.get("api/departments/all");
    },

    async getDepartmentWiseAssignEmployee(deptId) {
      if (deptId) {
        return await this.$api.get(`api/department/${deptId}/users`);
      }
    },

    async getAllUsers() {
      return await this.$api.get(`api/users/all`);
    },

    filterSupervisor() {
      this.$store.dispatch("allTaskModule/setFilter", {
        key: "filterSupervisorId",
        value: this.filterSupervisorId,
      });

      this.loadItems()
    },

    filterTaskStatusData() {
      this.$store.dispatch("allTaskModule/setFilter", {
        key: "filterTaskStatus",
        value: this.filterTaskStatus,
      });

      this.loadItems()
    },

    async loadItems() {
      try {

        this.isLoading = true

        const { startDate, endDate } = formatDateRange(this.filterRangeDate)

        const [globaldata] = await Promise.all([
          this.getGlobalData({
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            q: this.searchTerm,
            filterMilestoneBoardId: this.filterMilestoneBoardId,
            filterSupervisorId: this.filterSupervisorId,
            filterAuthUserId: this.filterAuthUserId,
            filterPhaseId: this.filterPhaseId,
            filterTaskId: this.filterTaskId,
            filterTaskStatus: this.filterTaskStatus,
            filterMilestoneStatus: this.filterMilestoneStatus,
            filterDateType: this.filterDateType,
            filterStartDate: startDate,
            filterEndDate: endDate,
            filterProjectId: this.filterProjectId,
            filterProjectLessBoardId: this.filterProjectLessBoardId,
          }),
        ]);

        const data = globaldata?.data?.data;

        const meta = globaldata?.data?.meta;
        this.totalRecords = meta?.pagination?.total;

        this.rows = data;

        this.isLoading = false

      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    async taskFiltering() {
      // Clear the previous timeout if it exists
      clearTimeout(this.denounceTimeout);

      // Call the loadItems immediately for the first input
      if (!this.denounceTimeout) {
        await this.loadItems()
      }

      // Set a new timeout for subsequent calls
      this.denounceTimeout = setTimeout(() => {
        this.loadItems()
      }, 3000)
    },
    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },
  },
};
</script>

  <style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/pages/ui-feather.scss";

.table-custom-style {
  font-size: 13px !important;
  white-space: nowrap !important;
  min-height: 140px !important;
  tr,
  th,
  td {
    //vertical-align: middle !important;
    //text-align: center !important;
  }
}

.custom-font {
  font-size: 13px !important;
}
.custom-font-validation {
  font-size: 11px;
}
.cell-with-description div {
  margin: 2px 0px;
}
.task-title {
  display: flex;
  align-items: center;
  margin-left: 50px;
  margin-top: 25px;
  margin-bottom: 10px;
}
.subtask-title {
  display: flex;
  align-items: center;
  margin-left: 50px;
  margin-bottom: 5px;
}

.subtask-title::before {
  content: "";
  display: inline-block;
  width: 8px; /* Adjust the size of the circle point as needed */
  height: 8px; /* Adjust the size of the circle point as needed */
  background-color: #c9c6c6; /* Adjust the color of the circle point as needed */
  border-radius: 50%;
  margin-right: 8px; /* Adjust the space between the circle point and the subtask title as needed */
}
.link {
  margin-left: 5px;
  margin-right: 5px;
}
.second-layer-margin {
  margin-top: 15px;
}
</style>
